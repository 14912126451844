.experts-opinions {
	padding: 3rem 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	.section-title {
		font-family: "Open Sans", sans-serif;
		font-size: 1.8rem;
		line-height: 1.5;
	}

	&__title {
		text-align: center;
		width: 50%;

		@media (max-width: 980px) {
			width: 80%;
		}
	}

	&__content {
		margin: 2rem 0;
		padding: 0 0 0 8rem;
		display: flex;
		flex-direction: row;
		width: 100%;
		@media (min-width: 980px) and (max-width: 1200px) {
			padding: 0 ;
			align-items: center;
			flex-direction: column;
		}
		@media (max-width: 979px) {
			padding: 0;
			flex-direction: column;
			align-items: center;
		}

		&__card {
			width: 40%;
			border-radius: 10px;

			// @media (min-width: 980px) and (max-width: 1200px) {
			// 	width: 80%;
			// }
			@media (max-width: 1200px) {
				padding: 0;
				width: 80%;
			}

			.card-wrapper {
				position: relative;
				background-position: center;
				background-repeat: no-repeat;
				background-size: cover;
				border-radius: 15px;

				&::before {
					content: "";
					display: block;
					position: absolute;
					height: 100%;
					width: 100%;
					left: 0;
					bottom: 0;
					border-radius: 15px;
					background: -webkit-gradient(
						linear,
						left top,
						left bottom,
						from(rgba(0, 0, 0, 0.2)),
						to(rgba(0, 0, 0, 0.5))
					);
					background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.8));
				}

				&__content {
					height: 90vh;
					margin: 0 auto;
					width: 80%;
					display: flex;
					flex-direction: column;
					text-align: center;
					align-items: center;
					justify-content: center;
					color: white;

					& > * {
						z-index: 2;
					}

					hr {
						border-color: rgb(105, 105, 105);
						margin: 1rem 0;
						width: 100%;
					}
				}
			}
		}

		&__opinions {
			width: 60%;
			padding-left: 8rem;
			display: flex;
			flex-direction: column;
			justify-content: center;
			@media (max-width: 1200px) {
				margin-top: 2rem;
				padding: 0;
				align-items: flex-start;
				width: 80%;
			}

			.opinion-wrapper {
				border-bottom: 1px solid rgb(105, 105, 105);
				padding: 1.5rem 0;
				width: 100%;
				&:last-of-type {
					border: initial;
				}

				@media (max-width: 1200px) and (min-width:980px){
					padding-left: 15%;
				}

				.section-title {
					width: 80%;

					@media (max-width: 550px) {
						width: 90%;
					}
		

				}

				.description {
					width: 70%;
					padding: 1rem 0;
					@media (max-width: 550px) {
						width: 90%;
					}
				}
			}
		}
	}
}
