.client-comments {
	display: flex;
	min-height: 70vh;
	padding: 2rem 1rem;

	@media (max-width: 980px) {
		flex-direction: column;
		height: initial;
	}
	@media (max-width: 600px) {
		padding: 2rem 2rem;
	}

	&__title {
		width: 40%;
		color: white;
		display: flex;
		align-items: center;
		justify-content: center;
		border-right: 1px solid #cdcdcd;
		height: 70%;
		align-self: center;
		@media (max-width: 980px) {
			width: 90%;
			padding-bottom: 2rem;
			border-bottom: 1px solid #cdcdcd;
			border-right: initial;
		}
		@media (max-width: 600px) {
			width: 100%;
		}
		.content-title {
			width: 26rem;
			display: flex;
			flex-direction: column;
			align-items: flex-start;

			@media (max-width: 600px) {
				width: initial;
			}

			.title {
				width: 17rem;
			}
		}
	}

	&__logos {
		width: 60%;

		@media (max-width: 980px) {
			width: 90%;
			height: 70vh;
			margin: 0 auto;
		}
		@media (max-width: 600px) {
			width: 100%;
			height: 80vh;
		}
		@media (max-width: 400px) {
			height: 100vh;
		}
	}
}
