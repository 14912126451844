.contact-page {
	// height: 90vh;
	background-color: white;

	&__content {
		position: relative;
		padding: 4rem 6rem;
		color: #181841;
		display: flex;
		flex-direction: column;
		align-items: center;
		@media (max-width: 1000px) {
			padding: 4rem 1rem;
		}

		h2.title {
			font-weight: 800;
			@media (max-width: 980px) {
				font-size: 2rem;
				text-align: center;
			}
		}

		.sections-display {
			margin-top: 2rem;
			width: 90%;
			min-height: 30rem;
			border: 1px solid #cdcdcd;
			border-radius: 25px;
			display: flex;
			flex-direction: row;
			padding: 1rem;
			@media (max-width: 600px) {
				width: 97%;
			}

			@media (max-width: 890px) {
				border: initial;
				height: initial;
			}

			&__navigation {
				border: 1px solid #cdcdcd;
				border-radius: 25px;
				width: 30%;
				padding: 2rem 1rem;
				@media (max-width: 890px) {
					width: 100%;
				}
				ul {
					list-style-type: none;

					li {
						margin-bottom: 1rem;
						.button {
							padding: 0.5rem 1rem;
							cursor: pointer;
							border-radius: 5px;
							font-weight: 800;
							color: #181841;
							&.active {
								background-color: #181841;
								color: white;
								transform: translateY(1.1);
							}

							&:hover {
								background-color: #181841;
								color: white;
								transform: translateY(1.1);
							}
							.number {
								color: #00ffb4;
								margin-right: 1rem;
							}

							.arrow-active {
								margin-right: 1rem;
								transform: rotateZ(-90deg);
							}
						}

						&:last-child {
							margin: initial;
						}

						.info-mobile {
							@media (min-width: 890px) {
								display: none;
							}
							background-color: white;
							height: 0;
							transition: 1s;
							visibility: hidden;
							opacity: 0;
							z-index: -100;
							padding: 1rem 0;

							&.active {
								height: initial;
								visibility: visible;
								opacity: 1;
								z-index: 1;
							}

							.title {
								color: #181841;
								text-align: initial;
							}
							.description {
								color: #555;
								margin-bottom: 1rem;
								// white-space: pre-wrap;
								@media (max-width: 600px) {
									font-size: 0.9rem;
								}

								ol,
								ul {
									// list-style: none;
									// counter-reset: li;
									list-style-position: inside;
									// &::before {
									// 	list-style-position: inside;
									// }
								}
								// li::before {
								// 	content: counter(li);
								// 	color: #00ffb4;
								// 	display: inline-block;
								// 	width: 1em;
								// 	margin-left: -1em;
								// 	font-weight: bold;
								// 	list-style-position: inside;
								// }
								// li {
								// 	counter-increment: li;
								// 	list-style-position: inside;
								// }
							}
						}
					}
				}
			}

			&__content-wrap {
				width: 70%;
				padding: 1.5rem 2.5rem;
				// overflow-y: scroll;
				@media (max-width: 890px) {
					display: none;
				}

				.description {
					// white-space: pre-wrap;
					ol,
					ul {
						// list-style: none;
						// counter-reset: li;
						list-style-position: inside;

						// &::before {
						// 	list-style-position: inside;
						// }
					}
					// li::before {
					// 	content: counter(li);
					// 	color: #00ffb4;
					// 	display: inline-block;
					// 	width: 1em;
					// 	margin-left: -1em;
					// 	font-weight: bold;
					// 	list-style-position: inside;
					// }
					// li {
					// 	counter-increment: li;
					// 	list-style-position: inside;
					// }
				}

				&::-webkit-scrollbar-track {
					-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
					border-radius: 10px;
					background-color: #f5f5f5;
				}

				&::-webkit-scrollbar {
					width: 12px;
					background-color: #f5f5f5;
				}

				&::-webkit-scrollbar-thumb {
					border-radius: 10px;
					-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
					background-color: #555;
				}
			}
		}
	}
}
