.footer {
	background-color: #181841;

	.footer-content {
		display: flex;
		padding: 3rem 8rem;
		color: white;

		@media (max-width: 1000px) {
			flex-direction: column-reverse;
			padding: 1rem 1.5rem;
		}

		.menu-section {
			width: 60%;
			@media (max-width: 1000px) {
				width: 100%;
			}

			.top {
				margin-bottom: 50px;

				&__text {
					max-width: 60%;
					@media (max-width: 1000px) {
						max-width: 100%;
					}
				}
			}

			.bottom {
				width: 60%;
				@media (max-width: 1000px) {
					width: 100%;
				}
				.menu {
					text-transform: uppercase;
					font-size: 90%;
					letter-spacing: 1px;
					.head {
						display: flex;
						justify-content: space-between;
						color: #00ffb4;
						flex-wrap: wrap;
						opacity: 0.7;
						padding: 2rem 0;
						border-bottom: 1px solid #282828;
						a {
							font-weight: 100;
						}
					}

					.links {
						display: flex;
						padding: 2rem 0;
						opacity: 0.7;
						span {
							font-weight: bold;
							margin-bottom: 1rem;
						}
						ul {
							margin-top: 0.5rem;
						}
						li {
							font-weight: 400;
							margin-bottom: 1rem;
							&:hover {
								color: #00ffb4;
							}
						}

						.individuals {
							margin-right: 3rem;
						}
					}
				}
			}

			ul {
				list-style: none;
			}
		}

		.company-section {
			width: 40%;
			position: relative;
			display: flex;
			flex-direction: column;
			align-items: flex-end;
			@media (max-width: 1000px) {
				align-items: flex-start;
				margin-bottom: 2rem;
				width: 100%;
			}

			&__logo {
				display: flex;
				flex-direction: column;
				align-items: flex-end;

				@media (max-width: 1000px) {
					flex-direction: row;
					justify-content: space-between;
					margin-bottom: 1rem;
				}

				.footer-logo {
					width: 100px;
				}

				.social-footer {
					text-align: center;
					margin-bottom: 50px;
				}
			}

			.widget {
				position: initial;

				&.location-slider {
					width: 23rem;

					@media (max-width: 1000px) {
						width: 90%;
					}
				}
			}
		}
	}

	.footer-copyright {
		border-top: 0.3px solid #282828;
		color: #cdcdcd;
		text-align: center;
		padding: 20px 0;
	}
}
