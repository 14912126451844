.cards-main {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-evenly;

	.card {
		height: 30rem;
		width: 22rem;
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		padding: 2rem 1rem;
		border-radius: 10px;
		position: relative;
		color: white;
		margin-bottom: 3rem;
		overflow: hidden;
		// @media (max-width: 768px) {
		// 	width: 25rem;
		// }
		cursor: pointer;

		&:hover > .description {
			visibility: visible;
			opacity: 1;
			margin-top: 2rem;
			max-height: 30rem;
			// position: relative !important;
		}

		&::before {
			content: "";
			position: absolute;
			border-radius: 10px;
			width: 100%;
			height: 100%;
			bottom: 0;
			left: 0;
			background: linear-gradient(
				to bottom,
				rgba(0, 0, 0, 0.5),
				rgba(0, 0, 0, 0.9)
			);
		}

		& > * {
			z-index: 2;
		}

		.card-header {
			.sub-title {
				h3 {
					font-weight: 100;
				}
			}
		}

		.description {
			margin-top: 0;
			visibility: hidden;
			opacity: 0;
			max-height: 0;
			transition: 1s all linear;
			// position: absolute;

			@media (max-width: 768px) {
				font-size: 0.9rem;
				visibility: visible;
				opacity: 1;
				margin-top: 2rem;
				max-height: 30rem;
			}
		}
	}
}
