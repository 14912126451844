.social-slider {
	bottom: 50px;
	right: 120px;

	.icon-wrapper {
		width: 150px;
		display: flex;
		align-items: center;
		justify-content: space-evenly;

		a {
			display: flex;
			.icon {
				height: 100%;
			}
		}
	}
}
