.what-you-need {
	padding: 3rem 4rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	@media (max-width: 768px) {
		padding: 3rem 2rem;
	}

	&__title {
		text-align: center;
		width: 50%;

		@media (max-width: 1100px) {
			width: 100%;
		}

		.content-title {
			display: flex;
			flex-direction: column;
			align-items: center;

			.description {
				max-width: 60%;
				margin: 0 auto;

				@media (max-width: 1100px) {
					max-width: 100%;
					margin: initial;
				}
			}

			.sub-tile {
				color: #00d496;
				font-weight: bold;
				font-size: 1.2rem;
				margin-top: 1rem;
				text-transform: uppercase;
			}
		}
	}

	&__cards {
		margin-top: 2rem;
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		grid-template-rows: auto;
		gap: 2rem 4rem;
		justify-items: center;
		width: 100%;

		@media (max-width: 1250px) {
			grid-template-columns: repeat(2, 1fr);
		}

		@media (max-width: 650px) {
			grid-template-columns: repeat(1, 1fr);
		}

		.type-card {
			width: 15rem;
			height: 20rem;
			position: relative;
			background-repeat: no-repeat;
			background-position: center;
			background-size: cover;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			color: white;
			border-radius: 5px;
			cursor: pointer;

			&::before {
				content: "";
				background: -webkit-gradient(
					linear,
					left top,
					left bottom,
					from(rgba(0, 0, 0, 0.2)),
					to(rgba(0, 0, 0, 0.5))
				);
				background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.5));
				width: 100%;
				height: 100%;
				display: block;
				position: absolute;
				bottom: 0;
				left: 0;
			}

			.icon,
			.card-title {
				z-index: 1;
			}

			.card-title {
				margin-top: 1rem;
				font-size: 1.3rem;
			}
		}
	}
}
