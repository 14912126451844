.comment-container.active {
	visibility: initial !important;
	opacity: 1 !important;
}

.comment-container {
	visibility: hidden;
	opacity: 0;
	transition: 2s;
}

.comments {
	color: white;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	.comments-items {
		position: relative;
		display: flex;
		height: 100%;
		width: 70%;
		flex-direction: column;
		justify-content: space-around;

		@media (max-width: 600px) {
			width: 100%;
		}

		.comments-head {
			position: relative;
			height: 20%;
			display: flex;
			align-items: center;

			.comment-container {
				position: absolute;

				.name {
					color: #00ffb4;
					font-family: "Merriweather";
					font-weight: 100;
				}
			}
		}
		.comments-navigation {
			z-index: 1500;
			display: flex;
			height: 30%;
			flex-direction: column;

			.navigation-arrows {
				display: flex;
				flex-direction: row;
				height: 70%;
				align-items: center;

				.arrowContainer {
					position: relative;
					width: 4rem;
					cursor: pointer;
					.backArrow {
						margin-right: 1rem;
					}

					.backArrow,
					.frontArrow {
						position: absolute;
					}

					.backArrow:hover {
						content: url("../../assets/icons/home/TESTIMONIALES/arrow_left_active.svg");
					}
					.frontArrow:hover {
						content: url("../../assets/icons/home/TESTIMONIALES/arrow_right_active.svg");
					}
				}
			}

			.navigation-dots {
				display: flex;

				.lines-navigation {
					border-bottom: 2px solid #cdcdcd;
					margin-right: 1rem;
					width: 2rem;
					&.active {
						border-bottom-color: #00ffb4;
					}
				}
			}
		}

		.comments-text {
			position: relative;
			flex-grow: 1;
			// overflow: hidden;
			.comment-container {
				position: absolute;
				top: 1rem;

				.text-big {
					font-size: 1.2rem;
					font-family: "Merriweather";
					font-weight: 100;
					@media (min-width: 1250px) and (max-width:1449px) {
						font-size: 1.1rem;
					}

					@media (max-width: 1249px) {
						font-size: 1rem;
					}
				}
			}
		}
	}
}
