.slider-main {
	height: 75vh;
	position: relative;

	@media (max-width: 980px) {
		height: 90vh;
		background-color: #181841;
	}
	@media (max-width: 680px) {
		height: 90vh;
	}

	.slider-title {
		position: absolute;
		top: 55%;
		left: 50%;
		transform: translate(-50%, -50%);
		color: white;
		text-align: center;

		@media (max-width: 980px) {
			top: 45%;
			width: 95%;
		}

		.content-title {
			display: flex;
			align-items: center;
			flex-direction: column;
		}
	}

	.slider-images {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: row;
		justify-content: space-between;

		@media (max-width: 980px) {
			align-items: flex-end;
		}

		&::before {
			content: "";
			display: block;
			position: absolute;
			width: 100%;
			height: 100%;
			background: rgb(24, 24, 65);
			background: linear-gradient(
				90deg,
				rgba(24, 24, 65, 0.1) 5%,
				rgba(24, 24, 65, 1) 25%,
				rgba(24, 24, 65, 1) 50%,
				rgba(24, 24, 65, 1) 75%,
				rgba(24, 24, 65, 0.1) 95%
			);
			bottom: 0;
			left: 0;

			@media (max-width: 980px) {
				background: linear-gradient(
					90deg,
					rgba(24, 24, 65, 0.1) 5%,
					rgba(24, 24, 65, 1) 50%,
					rgba(24, 24, 65, 0.1) 95%
				);
			}
		}

		.background {
			width: 30%;
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center;
			transition: 2s;
			@media (max-width: 600px) {
				width: 50%;
				height: 30%;
			}
			@media (min-width: 601px) and (max-width: 980px) {
				width: 50%;
				height: 40%;
			}
		}
	}

	.slider-arrows {
		width: 100%;
		position: absolute;
		top: 50%;

		@media (max-width: 980px) {
			top: initial;
			bottom: 10rem;
		}

		.arrowsContainer {
			display: flex;
			flex-direction: row;
			justify-content: center;
			color: white;
			padding: 0 5rem;
			@media (max-width: 980px) {
				padding: 0 1rem;
			}

			.arrowContainer {
				position: relative;
				width: 50%;
				margin: 0 auto;
				cursor: pointer;
				display: flex;
				justify-content: flex-start;
				&.right {
					justify-content: flex-end;
				}

				.backArrow,
				.frontArrow {
					position: absolute;
					width: 8rem;
					height: 10rem;
					.page-title {
						letter-spacing: 2px;
					}
				}

				.backArrow {
					display: flex;
					flex-direction: column;
					align-items: flex-start;
				}
				.frontArrow {
					display: flex;
					flex-direction: column;
					align-items: flex-end;
				}

				.backArrow:hover > img.prev-insurance {
					content: url("../../assets/icons/home/TESTIMONIALES/arrow_left_active.svg");
				}
				.frontArrow:hover > img.next-insurance {
					content: url("../../assets/icons/home/TESTIMONIALES/arrow_right_active.svg");
				}
			}
		}
	}
}
