.contact-home {
	padding: 4rem 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	color: white;
	height: 55vh;
	justify-content: center;

	@media (max-width: 850px) {
		height: initial;
	}

	& > * {
		z-index: 2;
	}

	&__title {
		text-align: center;
		width: 50%;

		@media (max-width: 850px) {
			width: 80%;
		}

		.description {
			width: 90%;
			margin: 0 auto;
			padding: 1rem 0;
		}
	}

	&__contact-actions {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		width: 40rem;
		padding: 2rem 0;

		@media (max-width: 850px) {
			flex-direction: column;
			align-items: center;
			width: 90%;
		}

		.button-wrapper {
			display: flex;

			@media (max-width: 850px) {
				margin-bottom: 2rem;
			}
		}
	}
}
