.widget {
	&.location-slider {
		bottom: 50px;
		left: 120px;
		display: flex;
		flex-direction: row;
		align-items: center;
	}

	.img {
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 0 20px 0 0;
	}

	.text {
		color: #cdcdcd;
		line-height: 1.2;
		max-width: 300px;

		.location {
			font-family: "Open Sans", sans-serif;
		}

		.number {
			color: white;
			display: block;
			font-size: 20px;
			line-height: inherit;
			&:hover {
				color: #00ffb4;
			}
		}
	}
}
