// .inactive {
// 	// visibility: hidden;
// 	// opacity: 0;
// }

@keyframes slideFade {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@keyframes slideFadeReverse {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
	}
}

.carousel {
	height: 100%;
	.carousel-items {
		position: relative;
		color: black;
		display: flex;
		align-items: flex-end;
		justify-content: center;
		height: 100%;
		overflow: hidden;

		.carousel-slide {
			position: absolute;
			width: 100%;
			top: 0;
			bottom: 0;
			@media (max-width: 1200px) {
				width: 90%;
			}

			.logos-container {
				display: flex;
				position: absolute;
				flex-wrap: wrap;
				align-items: center;
				justify-content: space-evenly;
				padding: 3rem;
				top: 0;
				bottom: 0;
				width: 90%;
				left: 50%;
				transform: translate(-50%, 0);
				opacity: 0;

				&.active {
					animation-name: slideFade;
					animation-duration: 0.3s;
					animation-fill-mode: forwards;
				}

				&.inactive {
					animation-name: slideFadeReverse;
				}

				@media (max-width: 600px) {
					padding: 5rem 0;
				}

				.logo {
					width: 15rem;
					margin: 0 1rem;
					// @media (min-width: 1150px) and (max-width:1250px) {
					// 	height: 3rem;
					// }
					@media (max-width: 1050px) and (min-width:770px) {
						width: 15rem;
					}
					@media (max-width: 769px) and (min-width:450px) {
						width: 12rem;
					}
					@media(max-width:449px){
						
					width: 11rem;
					}
				}
			}
		}
		.carousel-navigation {
			z-index: 1500;

			.dot-navigation {
				margin: 0 10px;
				cursor: pointer;
				&.active {
					color: #00ffb4;
				}

				@media (max-width: 600px) {
					font-size: 2rem;
				}
			}
		}
	}
}
