.allies {
	display: flex;
	height: 45vh;

	@media (max-width: 1100px) {
		flex-direction: column;
		align-items: center;
		height: initial;
		padding: 2rem 0;
	}

	&__title {
		width: 30%;
		color: black;
		display: flex;
		align-items: center;
		justify-content: center;
		.title {
			width: 17rem;

			@media (max-width: 1100px	) {
				width: initial;
				text-align: center;
			}
		}

		@media (max-width: 1100px	) {
			width: 90%;
			// margin-bottom: 2rem;
		}
	}

	&__logos {
		width: 70%;

		@media (max-width: 1100px) {
			height: 70vh;
			width: 100%;
		}
	}
}
