.our-products {
	// height: 90vh;
	scroll-behavior: smooth;

	&__slider-wrapper {
		position: relative;
	}

	&__content-wrapper {
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;

		&__title {
			margin: 2rem 0;
			display: flex;
			flex-direction: column;
			align-items: center;

			.toggle-wrap {
				display: flex;
				width: 100%;
				align-items: center;
				justify-content: space-evenly;
				color: #aaaaaa;
				font-weight: 600;
				text-transform: uppercase;
				.active {
					color: #00d496;
				}
			}
		}

		&__cards {
			position: relative;
			width: 75%;
		}

		&__button {
			position: relative;
			width: 30%;
			margin: 4rem 0;
			text-align: center;

			@media (max-width: 980px) {
				width: 90%;
			}

			.button-wrapper {
				margin: 2rem 0;
			}
		}
	}
}
