.container {
	display: flex;
	flex-direction: row;
	align-items: center;

	.img {
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 0 20px;
	}

	.text {
		color: #cdcdcd;
		line-height: 1;
		letter-spacing: 2px;
		@media (max-width: 768px) {
			font-size: 0.9rem;
		}
		.number {
			letter-spacing: normal;
			color: white;
			display: block;
			line-height: inherit;
			&:hover {
				color: #00ffb4;
			}
		}
	}
}
