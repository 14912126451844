.header {
	position: absolute;
	height: 100px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	padding: 0 120px;
	top: 20px;
	z-index: 1000;
	@media (max-width: 1500px) {
		padding: 0 2rem;
	}
	@media (max-width: 500px) {
		padding: 0 1rem;
	}
	@media (max-width: 1050px) {
		justify-content: space-between;
	}

	.logo-header {
		@media (min-width: 1051px) {
			width: 15%;
		}
		.logo {
			margin-top: 30px;
		}
	}

	.right-item {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		.contact-info {
			@media (max-width: 320px) {
				display: none;
			}
		}

		@media (min-width: 1051px) {
			width: 15%;
		}
	}

	.menu-mobile-button {
		cursor: pointer;
		margin-left: 1rem;
		@media (min-width: 1050px) {
			display: none;
		}
		z-index: 2000;
	}

	.mobile-menu {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 0;
		background-color: #181841;
		z-index: 1000;
		color: white;
		transition: 0.4s;
		display: flex;
		align-items: center;
		justify-content: center;
		max-width: 100vw;

		&.active {
			height: 70vh;
		}
		&.active > .mobile-menu__menu-items {
			display: initial;
		}

		&__menu-items {
			list-style: none;
			display: none;

			&__item {
				padding: 10px 30px;
				text-transform: uppercase;
				position: relative;
				cursor: pointer;
				font: inherit;

				@media (max-width: 1165px) {
					padding: 10px 1rem;
				}

				&:hover {
					color: #00ffb4;
					transition: 1s;
				}

				&__sub-menu {
					padding-left: 1rem;
					list-style-type: none;

					&__item {
						color: #00ffb4;
						&:hover {
							color: white;
						}
					}
				}
			}
		}
	}

	.menu-items {
		display: flex;
		justify-content: center;
		list-style: none;
		color: #cdcdcd;

		@media (max-width: 1500px) {
			margin-right: 1rem;
		}
		@media (max-width: 1050px) {
			display: none;
		}

		&__item {
			padding: 10px 30px;
			text-transform: uppercase;
			position: relative;
			cursor: pointer;
			font: inherit;

			@media (max-width: 1165px) {
				padding: 10px 1rem;
			}

			&:hover {
				color: #00ffb4;
				transition: 1s;
			}

			&:hover > .menu-items__item__sub-menu {
				visibility: initial;
				opacity: 1;
				z-index: 1000;
				top: 4rem;
			}

			&__sub-menu {
				position: absolute;
				transition: 2s;
				visibility: hidden;
				opacity: 0;
				z-index: -100;
				list-style-type: none;
				top: 0;
				padding: 1rem 2rem;
				background: #0e0f27;

				&__item {
					color: white;
					&:hover {
						color: #00ffb4;
					}
				}
			}
		}
	}
}
