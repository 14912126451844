@import "~react-datepicker/dist/react-datepicker.css";
// .form-wrapper {
// 	width: 70%;
// 	margin: 0 auto;
// 	display: flex;
// 	flex-direction: column;
// 	align-items: center;

// 	ul {
// 		width: 100%;
// 	}

// 	&__inputs-wrapper {
// 		margin-bottom: 3rem;
// 		width: 100%;

// 		&__input-group {
// 			padding: 3rem;
// 			border: 1px solid #cdcdcd;
// 			border-radius: 25px;

// 			.input-row {
// 				display: flex;
// 				flex-direction: row;
// 				align-items: center;

// 				&__column {
// 					&__1 {
// 						width: 100%;
// 						display: flex;
// 						flex-direction: column;
// 						display: flex;
// 						flex-direction: column;
// 						padding: 1rem 2rem 1rem 0;
// 						label {
// 							font-family: "Open Sans", sans-serif;
// 							font-weight: bold;
// 							font-size: 0.9rem;
// 						}
// 						input {
// 							padding: 1rem 2rem;
// 							width: 100%;
// 						}
// 					}
// 					&__2 {
// 						width: 50%;
// 						display: flex;
// 						flex-direction: column;
// 						display: flex;
// 						flex-direction: column;
// 						padding: 1rem 2rem 1rem 0;
// 						label {
// 							font-family: "Open Sans", sans-serif;
// 							font-weight: bold;
// 							font-size: 0.9rem;
// 						}
// 						input {
// 							padding: 0.5rem 1.5rem;
// 							border: 1px solid #cdcdcd;
// 							border-radius: 5px;
// 							width: 100%;
// 						}

// 						.toggle-wrapper {
// 							display: flex;
// 							flex-direction: row;
// 							margin-top: 1rem;
// 							span {
// 								font-weight: bold;
// 								font-size: 0.9rem;
// 							}
// 							input {
// 								width: initial;
// 								align-self: flex-end;
// 							}

// 							label {
// 								margin-right: 2rem;
// 							}
// 						}
// 					}
// 				}
// 			}
// 		}
// 	}
// }

.family-size {
	display: flex;
	justify-content: space-between;
	align-items: center;

	.count-family {
		text-align: center;
		font-size: 1.5rem;
	}

	.remove-family,
	.add-family {
		width: 20%;
		cursor: pointer;
	}
}

.family {
	position: relative;

	.remove-family {
		position: absolute;
		top: 0.5rem;
		right: 2rem;
		color: #cdcdcd;
		font-size: 2rem;
		cursor: pointer;
	}
}

.checkbox-wrapper {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	flex-wrap: wrap;
	max-width: 100%;
	margin: 1rem 0;

	.checkbox-child {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		width: 12rem;
		border: 1px solid #cdcdcd;
		border-radius: 5px;
		padding: 0.5rem;
		transition: 1s;
		margin-bottom: 1.5rem;
		position: relative;

		&.checked {
			border: 1px solid #00d496;
		}

		&:hover {
			border: 1px solid #00d496;
		}

		.container-checkbox {
			display: flex;
			background-color: white;
			background-size: 100%;
			position: relative;

			-webkit-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;
			div {
				display: flex;
				align-items: center;

				input {
					width: 20px;
					height: 20px;
					transition: 0.2s;
					cursor: pointer;
					opacity: 0;
					position: absolute;

					& ~ label {
						padding: 0.5rem 1rem;
						cursor: pointer;
					}

					& ~ div {
						width: 20px;
						height: 20px;
						background-size: 100%;
						border: 1px solid #868686;
						border-radius: 5px;

						&:after {
							content: "";
							position: absolute;
							display: none;
						}
					}

					&:checked ~ div {
						background-color: #00d496;
						background-size: 100%;

						&:after {
							display: block;
							left: 7px;
							top: 4px;
							width: 7px;
							height: 10px;
							border: solid white;
							border-width: 0 3px 3px 0;
							transform: rotate(45deg);
						}
					}
				}
			}
		}

		label {
			word-break: keep-all;
			width: 80%;
			font: 400 13.3333px Arial;
			margin: 0;
		}

		.tooltip-container {
			.tooltip {
				color: #00d496;
				font-weight: bold;
				font-family: "Merriweather", serif;
				cursor: pointer;
			}

			.tooltip:hover ~ .message {
				opacity: 1;
				visibility: visible;
			}

			.message {
				visibility: hidden;
				opacity: 0;
				transition: 1s;
				position: absolute;
				width: max-content;
				padding: 0.5rem 2rem;
				border: 1px solid #cdcdcd;
				border-radius: 10px;
				background: white;
				z-index: 1000;
				box-shadow: rgba(0, 0, 0, 0.2) 2px 2px 7px;
				font: 400 13.3333px Arial;
				right: 0;
				bottom: -40px;
			}
		}
	}
}
.react-switch-checkbox {
	width: 60px;
}

.react-switch-label {
	display: flex;
	align-items: center;
	justify-content: space-between;
	cursor: pointer;

	width: 50px;
	height: 25px;
	background: #eee;
	border: 1px solid #00d496;
	border-radius: 100px;
	position: relative;
	transition: background-color 0.2s;
}

.react-switch-label .react-switch-button {
	content: "";
	position: absolute;
	top: 2px;
	left: 2px;
	width: 20px;
	height: 20px;
	border-radius: 45px;
	transition: 0.2s;
	background: #00d496;
	box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
}

.react-switch-checkbox:checked + .react-switch-label .react-switch-button {
	left: calc(100% - 2px);
	transform: translateX(-100%);
}

.hide {
	visibility: hidden;
	opacity: 0;
	transition: 1s;
}

.symbol {
	display: flex;
	text-align: center;
	align-items: center;
	justify-content: center;
	color: #00d496;
	border: 2px solid #00d496;
	border-radius: 50%;
	height: 3rem;
	width: 3rem;
	font-size: 2rem;
	line-height: 0;
	transition: 1s;
}
.symbol:hover {
	background-color: #181841;
}
