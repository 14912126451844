.our-differences {
	position: relative;
	display: flex;
	height: 95vh;

	@media (max-width: 1050px) {
		flex-direction: column;
		height: initial;
	}

	&__info {
		width: 35%;
		background-repeat: no-repeat;
		background-position: center center;
		background-size: cover;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		padding: 150px 70px;
		color: white;
		position: relative;
		&::before {
			content: "";
			display: block;
			position: absolute;
			width: 100%;
			height: 100%;
			background: -webkit-gradient(
				linear,
				left top,
				left bottom,
				from(transparent),
				to(rgba(0, 0, 0, 0.8))
			);
			background: linear-gradient(transparent, rgba(0, 0, 0, 0.8));
			bottom: 0;
			left: 0;
		}

		@media (max-width: 1050px) {
			width: 100%;
			height: 70vh;
			align-items: center;
			text-align: center;
			padding: 4rem 3rem;
			background-position: top center;
		}

		@media (max-width: 600px) {
			background-position: center center;
		}

		&__content {
			z-index: 1;

			.description {
				margin: 1rem 0 3rem 0;
			}
		}
	}

	&__cards-section {
		width: 65%;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: #eeeeee;

		@media (max-width: 1050px) {
			width: 100%;
		}

		&__cards {
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-row-gap: 2rem;
			row-gap: 2rem;
			grid-column-gap: 2rem;
			column-gap: 3rem;
			justify-items: center;
			padding: 9rem;
			justify-content: center;

			@media (max-width: 1200px) {
				padding: 4rem;
			}
			@media (max-width: 600px) {
				grid-template-columns: 1fr;
				justify-items: start;
				padding: 3rem 2rem;
			}

			.card {
				font-size: 0.9rem;

				p {
					@media (max-width: 600px) {
						display: none;
					}
				}
			}
		}
	}
}
