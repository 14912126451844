.form-wrapper {
	width: 70%;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	align-items: center;

	.thank-you {
		text-align: center;
	}

	@media (max-width: 690px) {
		width: 95%;
	}
	ul {
		width: 100%;
	}
	&__inputs-wrapper {
		margin-bottom: 3rem;
		width: 100%;

		&__input-group {
			padding: 3rem;
			border: 1px solid #cdcdcd;
			border-radius: 25px;

			.input-row {
				display: flex;
				flex-direction: row;
				align-items: flex-start;
				@media (max-width: 980px) {
					flex-direction: column;
				}

				&__column {
					&__1 {
						width: 100%;
						display: flex;
						flex-direction: column;
						padding: 1rem 0rem;
						& > :first-of-type(div) {
							color: red;
						}

						label {
							font-family: "Open Sans", sans-serif;
							font-weight: bold;
							font-size: 0.9rem;
							margin-bottom: 1rem;
						}
						input {
							padding: 5px 10px;
							height: 48px;
						}
					}
					&__2 {
						width: 50%;
						display: flex;
						flex-direction: column;
						display: flex;
						flex-direction: column;
						padding: 1rem 2rem 1rem 0;
						@media (max-width: 980px) {
							width: 100%;
						}
						label {
							font-family: "Open Sans", sans-serif;
							font-weight: bold;
							font-size: 0.9rem;
							margin-bottom: 1rem;
						}
						input {
							border: 1px solid #cdcdcd;
							border-radius: 5px;
							width: 100%;

							padding: 5px 10px;
							height: 48px;
						}

						.toggle-wrapper {
							display: flex;
							flex-direction: row;
							margin-top: 1rem;
							span {
								font-weight: bold;
								font-size: 0.9rem;
							}
							input {
								width: initial;
								align-self: flex-end;
							}

							label {
								margin-right: 2rem;
							}

							.radio-option {
								display: flex;
								align-items: center;
								margin-right: 1rem;

								&__radio-container {
									display: flex;
									background-color: white;
									background-size: 100%;
									position: relative;

									-webkit-user-select: none;
									-moz-user-select: none;
									-ms-user-select: none;
									user-select: none;
									div {
										display: flex;
										align-items: center;

										input {
											width: 20px;
											height: 20px;
											transition: 0.2s;
											cursor: pointer;
											opacity: 0;
											position: absolute;

											& ~ label {
												padding: 0.5rem 1rem;
												cursor: pointer;
											}

											& ~ div {
												width: 20px;
												height: 20px;
												background-size: 100%;
												border: 1px solid #868686;
												border-radius: 50%;

												&:after {
													content: "";
													position: absolute;
													display: none;
												}
											}

											&:checked ~ div {
												background-color: #00d496;
												background-size: 100%;

												&:after {
													display: block;
													border-radius: 50%;
													left: 50%;
													top: 50%;
													transform: translate(-50%, -50%);
													width: 15px;
													height: 15px;
													border: solid white;
												}
											}
										}
									}
								}

								span {
									margin: 0;
									margin-left: 4px;
									font: 400 13.3333px Arial;
								}
							}
						}
					}
				}
			}
		}
	}
}
