.btn {
	padding: 15px 40px;
	border: 2px solid #00d496;
	color: #00d496;
	border-radius: 100px;
	text-transform: uppercase;
	font-weight: 800;
	cursor: pointer;
	letter-spacing: 3px;
	text-align: center;
	white-space: pre-wrap;
	font-size: 1rem;
	@media (max-width: 350px) {
		font-size: 0.6rem;
	}

	&:hover {
		transition: 1s;
		background-color: #00d496;
		color: #181841;
	}

	&.primary {
		border-color: #181841;
		&:hover {
			background-color: #181841;
		}
	}

	&--red {
		color: #ca0b2b;
	}
	// &--green {
	// 	color: #00d496;
	// }
}
