.slider-internal {
	height: 70vh;
	background-color: #181841;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;

	@media (max-width: 980px) {
		height: 90vh;
	}

	&.background-image {
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
		&::before {
			content: "";
			display: block;
			position: absolute;
			width: 100%;
			height: 100%;
			background: -webkit-gradient(
				linear,
				left top,
				left bottom,
				from(rgba(24, 24, 65, 0.6)),
				to(rgba(24, 24, 65, 1))
			);
			background: linear-gradient(rgba(24, 24, 65, 0.6), rgba(24, 24, 65, 1));
			bottom: 0;
			left: 0;
		}

		& > * {
			z-index: 1;
		}
	}

	&__title {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 50%;
		text-align: center;

		@media (max-width: 980px) {
			width: 90%;
		}

		&__page-indicator {
			background-color: #00ffb4;
			color: #181841;
			padding: 0.5rem 1.5rem;
			border-radius: 100px;
			font-size: 0.8rem;
			font-weight: 700;
			letter-spacing: 2px;
			text-transform: uppercase;

			@media (max-width: 1499px) {
				display: none;
			}
		}
		&__title {
			color: white;
			line-height: 1.3;
		}

		&__description {
			width: 70%;
			color: white;
			text-align: center;
			margin-top: 1rem;
			@media (max-width: 980px) {
				width: 100%;
			}
		}
	}

	&__number {
		position: absolute;
		bottom: 2rem;
		left: 4rem;
		.container {
			display: flex;
			flex-direction: row;
			align-items: center;

			.img {
				display: flex;
				align-items: center;
				justify-content: center;
				margin: 0 20px;
			}

			.text {
				color: #cdcdcd;
				line-height: 1;
				letter-spacing: 2px;
				font-size: 0.8rem;
				.number {
					font-size: 1rem;
					letter-spacing: normal;
					color: white;
					display: block;
					line-height: inherit;
					letter-spacing: 1px;
					color: white;
					display: block;
					line-height: 1;
					&:hover {
						color: #00ffb4;
					}
				}
			}
		}
	}
}
