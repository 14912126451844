.content {
	position: absolute;
	left: 50%;
	top: 55%;
	z-index: 100;
	transform: translate(-50%, -50%);
	text-align: center;
	color: white;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 95%;

	@media (max-width: 500px) {
		top: 50%;
	}

	.title {
		margin-bottom: 20px;
	}

	.description {
		margin-bottom: 50px;
		max-width: 500px;
		@media (max-width: 768px) {
			max-width: 300px;
		}
	}
}
