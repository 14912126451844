.form-page {
	background-color: white;

	&__form {
		padding: 4rem 6rem;

		@media (max-width: 690px) {
			padding: 4rem 1rem;
		}
	}
}
