.jbl-experience {
	position: relative;

	&__contact {
		position: relative;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;

		&:before {
			content: "";
			width: 100%;
			height: 100%;
			bottom: 0;
			left: 0;
			position: absolute;
			background: -webkit-gradient(
				linear,
				left top,
				left bottom,
				from(rgba(0, 0, 0, 0.2)),
				to(rgba(0, 0, 0, 0.5))
			);
			background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.8));
		}
	}

	&__history {
		position: relative;
		display: flex;
		flex-direction: row;
		align-items: center;
		padding: 5rem 8rem;

		@media (max-width: 970px) {
			flex-direction: column;
			padding: 5rem 2rem;
		}

		@media(max-width:1320px){
			padding: 5rem 2.5rem;
		}
		

		&__title,
		&__text {
			width: 50%;
			white-space: pre-wrap;
			@media (max-width: 970px) {
				width: 100%;
			}

			h1.title{
				
				@media (max-width: 970px) {
					max-width: 100%;
				}
			}

		}

		&__text{
			@media (max-width: 970px) {
				
				display: flex;
				justify-content: center;
			}
		}

		&__title {
			h1 {
				font-size: 2.8rem;
				@media (max-width: 1250px) {
					font-size: 1.8rem;
				}
			}
			@media (min-width: 891px) and (max-width: 1250px) {
				padding: 0 2rem;
			}
			@media (max-width: 970px) {
				padding: 0;
				margin-bottom: 1rem;
			}
			@media(max-width:640px){
				width: 70%;
			}

			padding: 0 1rem;
		}
	}

	&__points {
		display: flex;
		flex-direction: row;
		align-items: center;
		background-color: #eee;
		height: 70vh;

		@media (max-width: 890px) {
			flex-direction: column-reverse;
			height: initial;
		}

		&__grid {
			width: 70%;
			display: flex;
			flex-direction: column;
			justify-content: center;
			padding: 0 15rem;

			@media (max-width: 1200px) {
				padding: 0 5rem;
			}
			@media (max-width: 1200px) {
				padding: 2rem 1rem;
			}
			@media (max-width: 768px) {
				width: 100%;
				padding: 2rem 2rem;
			}

			.grid-wrapper {
				display: grid;
				grid-template-columns: repeat(2, 1fr);
				grid-row-gap: 2rem;
				row-gap: 2rem;
				grid-column-gap: 2rem;
				column-gap: 3rem;
				margin-top: 2rem;

				@media (max-width: 530px) {
					grid-template-columns: repeat(1, 1fr);
				}

				.card {
					font-size: 0.9rem;
				}
			}
		}
		&__image {
			width: 30%;
		}

		&__image {
			height: 100%;
			background-position: center;
			background-repeat: no-repeat;
			background-size: cover;

			@media (max-width: 890px) {
				width: 100%;
				height: 70vh;
			}
		}
	}

	&__our-team {
		background-color: #181841;
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 2rem 0;
		color: white;

		@media (max-width: 980px) {
			text-align: center;
		}

		&__cards {
			margin: 7rem 0;
			display: flex;
			flex-direction: row;
			justify-content: space-evenly;
			flex-wrap: wrap;
			max-width: 95%;
			@media (max-width: 980px) {
				margin: 3rem 0;
			}
			.card-wrapper {
				display: flex;
				flex-direction: column;
				align-items: center;
				transition: 1s;
				margin: 0 0.5rem;
				z-index: 0;

				@media (max-width: 980px) {
					margin-bottom: 2rem;
				}

				&:hover > .card-wrapper__content {
					opacity: 1;
					visibility: visible;
				}

				&:hover > .card-wrapper__image {
					border-bottom: 5px solid #00ffb4;
				}

				&:hover {
					transform: scale(1.2);
					z-index: 2;
				}

				&__image {
					width: 15rem;
					height: 15rem;
					background-position: top;
					background-repeat: no-repeat;
					background-size: cover;
					transition: 1s;
				}

				&__content {
					text-align: center;
					visibility: hidden;
					opacity: 0;
					transition: 1s;

					@media (max-width: 980px) {
						opacity: 1;
						visibility: visible;
					}

					.name {
						font-weight: 900;
					}
					.description {
						font-weight: 500;
						font-size: 0.8rem;
					}
				}
			}
		}
	}
}
